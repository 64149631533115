import React, {useEffect, useState, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import iconAddComment from '../../assets/icon_add_comment.svg';
import { FwTooltip, FwModal, FwButton, FwTextarea, FwSelect, FwSelectOption, FwModalTitle, FwModalContent, FwModalFooter  } from "@freshworks/crayons/react";
import {formatDate} from '../utils/FormatHelper';
import DOMPurify from 'dompurify';
import ReactDOM from "react-dom";

const OrderDocuments = ({orderId, orderDocuments, onDownloadDocument }) => {

    const { t } = useTranslation();

    //variable open = true
    const [open, setOpen] = useState(true);


    const [documentsList, setDocumentsList] = useState([]);
    const [documentsCount, setDocumentsCount] = useState(0);
    const [isActiveOrderDocuments, setIsActiveOrderDocuments] = useState(false);
    const toggleOrderDocumentsList = () => {
        setIsActiveOrderDocuments(!isActiveOrderDocuments);
    };

    useEffect(() => {
        const documents = orderDocuments || [];
        setDocumentsList(documents);
        setDocumentsCount(documents.length);
    }, [orderDocuments]);

    const modalRef = useRef();
    const textareaRef = useRef();
    const modalContentRef = useRef();



    useEffect(() => {
        setTimeout(() => {
        if (modalContentRef.current && modalContentRef.current.shadowRoot) {
            const shadowRoot = modalContentRef.current.shadowRoot;
            console.log('shadowRoot for ModalContent', shadowRoot);
            const style = document.createElement('style');
            style.textContent = '.content { padding-inline: 3px !important; }';
            shadowRoot.appendChild(style);
        }
        }, 100);
    }, []); 


  return (
    <div>

            <div className="flex justify-between items-center mt-1 border border-gray-100 py-0.5">
                <button className={`orderDetailText font-bold text-left rounded-sm pl-0.5 flex-grow ${documentsCount ? 'text-gray-700' : 'text-gray-400 hover:text-gray-500'}`} disabled={ documentsCount === 0 ? true : false } onClick={toggleOrderDocumentsList}>{t('documents')} </button>
                <div className="flex orderDetailText items-center text-left text-gray-700 font-bold  rounded-sm pl-0.5">
                    <div className={`bg-gray-50 rounded-full border-md border-gray-600 mx-1 font-bold text-l px-1.5 ${documentsCount ? 'text-gray-700 cursor-pointer' : 'text-gray-400 hover:text-gray-500'}`} onClick={toggleOrderDocumentsList}>
                            {documentsCount}
                            {documentsCount > 0 ? <div className={`collapseToggle ml-2 ${isActiveOrderDocuments ? 'rotate' : ''}`}>▼</div> : null}
                    </div>
                </div>
            </div>

            

        <div className="orderListItemsWrapper">
            <div id="orderDocumentsList" className={`orderItemList  ${isActiveOrderDocuments ? 'active' : ''}`} >
                {documentsList.map((orderDocument, index) => (
                    <div key={index} className={`${index === 0 ? 'mt-1' : ''}`}>
                        <div className="mb-1 p-1 bg-slate-50 border-gray-100 border rounded-sm">
                            <div className="w-full orderDetailText text-gray-700">{formatDate(orderDocument.createdAt)}</div>

                            <a data-test-id="item-comments-subitem" className="text-sm text-gray-600 cursor-pointer underline" onClick={() => onDownloadDocument(orderDocument)}>{t(orderDocument.type)}</a>

                            
                        </div>
                        
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default OrderDocuments
